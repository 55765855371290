import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, Output, inject } from '@angular/core';
import { FormsService } from 'src/app/core/services/forms.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { CardType } from '../../../core/enums/card-type.enum';
import { ActionsService } from '../../../core/services/actions.service';
import { TriggerService } from '../../../core/services/trigger.service';
import { createMask } from '@ngneat/input-mask';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataForm } from '../../../core/models/dataForm.model';
import { CookieService } from 'ngx-cookie-service';
import { RoleEnum } from 'src/app/features/agora-pages/demo/enums/role.enum';
import { AgoraCurrency } from 'src/app/core/pipes/agoraCurrency.pipe';
import { TranslateService } from '@ngx-translate/core';
import { WishlistService } from '../../../core/services/V2/wishlist.service';
import { CartService } from '../../../core/services/V2/cart.service';
import { getProfilePictureOrDefault } from '../../../core/utils/filter.utils';
import { ResourcesStore } from '../../../stores/resources.store';
import { UserStore } from '../../../stores/user.store';
import { GetClubInformationResponse, VoucherResponse } from '@victoria-company/agora-client';
import { UserService } from '../../../core/services/V2/user.service';
import { ContextStore } from '../../../stores/context.store';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() title: string;
  @Input() titleNextStep: string;
  @Input() template: string;
  @Input() item: any;
  @Input() clubInformation: GetClubInformationResponse;
  @Input() width: any;
  @Input() modalClass: any;
  @Input() isWishlist = false;
  @Input() hiddeLink = false;
  @Input() isClosable = true;
  @Input() isDemo = false;
  @Input() clientGuid: string;
  @Input() demoCode: string;
  @Input() resolvedId: string;
  @Input() wishlistElement: any;
  @Input() cartId: string;

  @Output() updateBirthDate = new EventEmitter();

  readonly resourcesStore = inject(ResourcesStore);
  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly userService = inject(UserService);

  public get bForm() {
    return this.birthDateForm?.controls;
  }

  //internal
  viewNextStep = false;
  value: any;
  formData: any = {};

  articleName: string;
  articleSizes: any[] = [];
  articleSelected: any;

  hasError = false;
  hasFormError = false;
  errorCode = 0;
  isLoading = false;
  cardType = CardType;
  voucherValue: any;
  voucherCreated: VoucherResponse;
  dateInputMask: any;
  birthDateForm: UntypedFormGroup;
  locale: string;

  role: RoleEnum;

  @Input()
  selectedTab = 'localisation';

  constructor(
    public modal: ModalService,
    private translation: TranslateService,
    private actionsService: ActionsService,
    private trigger: TriggerService,
    private fb: UntypedFormBuilder,
    private cookie: CookieService,
    public formService: FormsService,
    private agoraCurrency: AgoraCurrency,
    private wishlistService: WishlistService,
    private cartService: CartService
  ) {
    this.modal.hasError$.subscribe(value => {
      this.hasError = value;
    });

    this.modal.errorCode$.subscribe(value => {
      this.errorCode = value;
    });

    this.modal.reset$.subscribe(() => {
      this.viewNextStep = false;
      this.hasError = false;
      this.hasFormError = false;
      this.value = undefined;
      this.formData = {};

      this.getDefaultCountry();

      if (this.template == 'size') {
        this.selectedTab = '0';
      }
      if (this.template == 'simpleSize') {
        this.selectedTab = '0';
      } else if (this.template == 'walletCreateVoucher') {
        this.selectedTab = 'createVoucherTab';
      } else {
        this.selectedTab = 'localisation';
      }
    });

    this.actionsService.createdVoucher$.subscribe(res => {
      if (res) {
        this.voucherCreated = res;
      }
    });

    this.trigger.resetDefaultValueAddVoucher$.subscribe(res => {
      if (res && this.clubInformation) {
        this.voucherValue = this.clubInformation.walletAmount.toFixed(2); //(this.currentWallet.total - this.currentWallet.totalVouchers).toFixed(2);
      }
    });
  }

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');

    if (this.item) {
      this.articleSizes = [];
      this.articleName = this.item?.name ?? this.item?.productGroup?.name;

      this.item.articles?.forEach(x => {
        const stockInfo = { isRAL: false, hasStock: true }; //TODO this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
        const displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE) || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
        const showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT;
        this.articleSizes.push({
          id: x.productVariantId,
          value: x.size + ' - ' + this.agoraCurrency.transform(x.salesPrice) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : ''),
          stock: x.stock,
          canSelect: displaySalesButton,
        });
      });
      this.articleSelected = this.articleSizes?.find(x => x.canSelect)?.id;
    }

    this.getDefaultCountry();

    this.dateInputMask = createMask<Date>({
      alias: 'datetime',
      inputFormat: 'dd/mm/yyyy',
      placeholder: '__/__/____',
      parser: (value: string) => {
        const values = value.split('/');
        const year = +values[2];
        const month = +values[1] - 1;
        const date = +values[0];
        return new Date(year, month, date);
      },
    });

    this.birthDateForm = this.formService.getBirthDateForm(this.fb, new DataForm());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.item && changes.item?.currentValue != undefined) {
      if (this.item) {
        this.articleSizes = [];
        this.articleName = this.item?.name ?? this.item?.productGroup?.name;

        this.item.articles?.forEach(x => {
          const stockInfo = { isRAL: false, hasStock: true }; //TODO this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
          const displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE) || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
          const showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT;
          this.articleSizes.push({
            id: x.productVariantId,
            value: x.size + ' - ' + this.agoraCurrency.transform(x.salesPrice) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : ''),
            stock: x.stock,
            canSelect: displaySalesButton,
          });
        });

        this.articleSelected = this.articleSizes?.find(x => x.canSelect)?.id;
      }
    }
  }

  getQuantity(quantity) {
    const stock = this.item.productGroup.products.find(x => x.sku == this.item.sku)?.stock;
    const max = stock >= 10 ? 10 : stock;
    const quantities = [];

    for (let i = 1; i <= max; i++) {
      quantities.push({ id: i, value: i });
    }

    if (max < quantity) {
      {
        quantities.push({ id: quantity, value: quantity });
      }
    }

    return quantities;
  }

  getDefaultCountry() {
    if (this.template == 'searchDelegate') {
      switch (this.contextStore.contextId()) {
        case 1:
          this.formData.country = 'FR';
          break;
        case 2:
          this.formData.country = 'BE';
          break;
        case 3:
          this.formData.country = 'DE';
          break;
        default:
          this.formData.country = 'FR';
          break;
      }
    }
  }

  selectItem() {
    if (this.articleSelected) {
      // if (this.isDemo) {
      //   this.demoService.postOrderItem(this.articleSelected, 1, this.clientGuid, this.resolvedId, this.item.isInWishlist, this.item.sku);
      //   this.modal.close('#' + this.id);
      //   return;
      // }
      // this.orderService.postGuestOrderItem(this.articleSelected, this.item, false, this.item.isInWishlist, this.item.sku);

      const wishlistItem = []; //this.wishlistVM()?.items.find(i => i.variants.map(a => a.productVariantId).find(variantId => variantId == this.articleSelected));
      const articleItem = []; //wishlistItem?.variants.find(x => x.productVariantId == this.articleSelected);

      console.log('wishlistItem : ', wishlistItem);
      console.log('articleItem : ', articleItem);
      // console.log("Add item ", articleItem?.productVariantId, " to cart ", this.cartVM()?.cartId);

      // this.cartService.add(articleItem?.productVariantId, this.cartVM()?.cartId).pipe(
      //   switchMap(_ => from(Promise.resolve(this.wishlistService.delete(wishlistItem?.id)))),
      // ).subscribe(  _ => {
      //   this.cartService.get();
      // });

      this.modal.close('#' + this.id);
    }
  }

  private async deleteItem() {
    console.log('item to delete : ', this.item);
    await this.cartService.delete(this.item.id, this.item.cartId);
    // .subscribe(
    //   res => {
    //     //this.cartService.getCart();
    //   },
    //   err => {
    //     console.log('Error deleteItem in modal');
    //   }
    // );

    // if (!this.isDemo) {
    //   this.orderService.deleteGuestOrderItem(this.item.sku, this.item);
    // } else {
    //   if(this.wishlistElement) {
    //     this.demoService.deleteWishlistItem(this.item.sku, this.clientGuid, this.resolvedId);
    //   } else {
    //     this.demoService.deleteOrderItem(this.item.sku, this.clientGuid, this.resolvedId);
    //   }
    // }
  }

  public confirmChoice(value: boolean) {
    if (value == true) this.deleteItem();
    this.modal.close('#' + this.id);
  }

  switchTab(tab: string) {
    this.hasError = false;
    this.hasFormError = false;
    this.selectedTab = tab;
  }

  validateFormAndCheck() {
    if (this.formData && this.formData.country && (this.formData.city || this.formData.zip)) {
      this.modal.executeAction({ actionName: 'searchDelegateBy', value: this.formData });
      this.modal.close('#' + this.id);
    } else {
      this.hasFormError = true;
    }
  }

  validateDelegateAndCheck() {
    if (this.formData.name && this.formData.name.trim() != '') {
      this.modal.executeAction({ actionName: 'searchDelegateBy', value: this.formData });
      this.modal.close('#' + this.id);
    } else {
      this.hasError = true;
    }
  }

  validateDelegate(delegate) {
    this.modal.executeAction({ actionName: 'selectDelegate', value: delegate });
    this.modal.close('#' + this.id);
  }

  goNextStep() {
    this.viewNextStep = true;
  }

  stepBack() {
    this.viewNextStep = false;
  }

  async createVoucherAction() {
    if (!this.voucherValue) {
      this.hasError = true;
      this.errorCode = 4;
      return;
    }
    this.isLoading = true;
    this.hasError = false;

    try {
      const voucherResponse = await this.userStore.createVoucherClubFromWallet(this.voucherValue);
      this.switchTab('voucherCreatedTab');
      this.actionsService.updateVoucher(voucherResponse);
      await this.userStore.getVouchers(0, true);
      await this.userStore.loadClubInformation();
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      this.hasError = true;
      this.errorCode = err.error?.errors[0].type;
    }
  }

  copyValue(event: MouseEvent, value: string) {
    navigator.clipboard.writeText(value).then(() => {});
    const el = event.target as HTMLElement;
    if (el) {
      el.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.add('display');
      setTimeout(() => {
        el.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.remove('display');
      }, 3000);
    }
  }

  closeCreatedVoucherModal() {
    this.modal.close('#walletCreateVoucher');
    this.selectedTab = 'createVoucherTab';
  }

  openCreationVoucherModal() {
    this.selectedTab = 'createVoucherTab';
    this.voucherValue = this.clubInformation.walletAmount.toFixed(2);
  }

  removeItemFromWishlist() {
    // this.removeItem(this.item);
    this.wishlistService.delete(this.item.id);
    this.modal.close('#' + this.id);
  }

  async download(code: string) {
    const result = await this.userService.downloadVoucher(code, this.contextStore.locale());
    console.log('Code to download : ', result);
    const mediaType = 'application/pdf';
    const blob = new Blob([result], { type: mediaType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  async addBirthDate(isMobile: boolean) {
    const data = this.birthDateForm.getRawValue();

    if (data.birthDate && this.birthDateForm.valid) {
      await this.userStore.addBirthdateToProfile(data.birthDate, this.contextStore.contextId());
      this.updateBirthDate.emit(data.birthDate);
      this.modal.close('#birthDateModal');
      this.modal.close('#myDatasBirthDateModal');
    } else this.formService.checkFormAndDisplayError(this.birthDateForm);
  }

  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
