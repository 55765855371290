import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormsService } from 'src/app/core/services/forms.service';
import { UserStore } from '../../../../../../stores/user.store';
import { ContextStore } from '../../../../../../stores/context.store';
import { StorageService } from '../../../../../../core/services/storage.service';
import { JWTService } from '../../../../../../core/services/jwt.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NotificationStore } from '../../../../../../stores/notification.store';
import { TranslateService } from '@ngx-translate/core';
import { NotificationIcon, NotificationStyle } from '../../../../../../core/types/notification.options.type';
import { UserService } from '../../../../../../core/services/V2/user.service';

@Component({
  selector: 'app-myacces',
  templateUrl: './myacces.component.html',
  styleUrls: ['./myacces.component.scss'],
})
export class MyaccesComponent implements OnInit {
  emailForm: UntypedFormGroup;
  operationPending = false;
  isDelegate: boolean;

  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly notificationStore = inject(NotificationStore);

  readonly storageService = inject(StorageService);
  readonly userService = inject(UserService);
  readonly jwtService = inject(JWTService);
  readonly translate = inject(TranslateService);
  readonly oidcService = inject(OidcSecurityService);

  public get fEmail() {
    return this.emailForm?.controls;
  }

  constructor(
    private fs: FormsService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.isDelegate = this.userStore.connectedAsRole() == 'Delegate';
    this.emailForm = this.fs.getChangeEmailForms(this.fb, this.userStore.profile()?.email);
  }

  async update() {
    if (this.emailForm.valid) {
      this.operationPending = true;

      try {
        await this.userStore.changeUserEmail(this.emailForm.get('newemail').value);
        this.notificationStore.notify({ message: this.translate.instant('ALERT.EMAIL.UPDATE'), icon: NotificationIcon.SUCCESS, style: NotificationStyle.SUCCESS });
        this.storageService.redirectTo = '/' + this.contextStore.locale() + '/club/profile/access';
        this.oidcService.logoff();
        this.jwtService.deleteTokenCookie();
      } catch (err) {
        this.notificationStore.notify({ message: this.translate.instant('ALERT.ERROR.GLOBAL'), icon: NotificationIcon.WARNING, style: NotificationStyle.DANGER });
      } finally {
        this.operationPending = false;
      }
    } else this.fs.checkFormAndDisplayError(this.emailForm);
  }

  async requestNewPassword() {
    if (!this.operationPending) {
      this.operationPending = true;

      try {
        await this.userService.requestStartSSOForgotPasswordFlow(this.userStore.profile()?.email);
        this.notificationStore.notify({ message: this.translate.instant('ALERT.PASSWORD.REQUESTED'), icon: NotificationIcon.SUCCESS, style: NotificationStyle.SUCCESS });
      } catch (err) {
        this.notificationStore.notify({ message: this.translate.instant('ALERT.ERROR.GLOBAL'), icon: NotificationIcon.WARNING, style: NotificationStyle.DANGER });
      } finally {
        this.operationPending = false;
      }
    }
  }
}
