<app-page-title mainTitle="{{ 'ENCODING.TITLE' | translate }}" subTitle="{{ 'ENCODING.SUBTITLE' | translate }}"> </app-page-title>

<div class="point-encoding-container flex j-center">
  <div class="center-section point-encoding flex center">
    <div class="flex j-center diamond-top m-only">
      <svg viewBox="0 0 32 32" class="width100 height100">
        <use xlink:href="#icon-diamond"></use>
      </svg>
    </div>
    <p class="d-only card-title">{{ 'ENCODING.CARD.TITLE' | translate }}</p>
    <div class="encoding">
      <div class="flex input-point-encoding">
        <div class="icon icon-left flex j-center diamond d-only">
          <svg viewBox="0 0 32 32" class="width100 height100">
            <use xlink:href="#icon-diamond"></use>
          </svg>
        </div>
        <div class="form-control flex-1 input" [class.error]="hasError" [formGroup]="pointEncodingForm">
          <label>{{ 'ENCODING.CARD.CODE' | translate }}</label>
          <input type="text" formControlName="code" mask="AAAA-AAAA-AAAA" [showMaskTyped]="true" [dropSpecialCharacters]="false" placeHolderCharacter="X" />
        </div>
        <div class="icon icon-right flex j-center info d-only">
          <app-tooltip [text]="'POINTENCODING.TOOLTIP' | translate" position="bottom"> </app-tooltip>
        </div>
      </div>
      <div class="flex j-start error-message">
        <label *ngIf="hasError && errorCode == -1" class="label-error">{{ 'GLOBAL.ERROR.CODEREQUIRED' | translate }}</label>
        <label *ngIf="hasError && errorCode == 21402" class="label-error">{{ 'GLOBAL.ERRORS.VOUCHER.ALREADYUSEDINACCOUNT' | translate }}</label>
        <label *ngIf="hasError && errorCode == 21401" class="label-error">{{ 'GLOBAL.ERRORS.VOUCHER.ALREADYUSED' | translate }}</label>
        <label *ngIf="hasError && errorCode == 300" class="label-error">{{ 'GLOBAL.ERROR.CLIENTNOTFOUND' | translate }}</label>
        <label *ngIf="hasError && errorCode == 21400" class="label-error">{{ 'GLOBAL.ERRORS.VOUCHER.NOTFOUND' | translate }}</label>
        <label *ngIf="hasError && errorCode != -1 && errorCode != 21401 && errorCode != 21402 && errorCode != 300 && errorCode != 21400" class="label-error">{{
          'GLOBAL.ERROR.GLOBAL' | translate
        }}</label>
      </div>
      <button id="addCodeButton" class="btn btn-primary j-center flex" (click)="saveCode()" [disabled]="addCodeLoading">
        <div *ngIf="addCodeLoading" class="loader loader-mini"></div>
        {{ 'GLOBAL.ACTION.SAVECODE' | translate }}
      </button>
    </div>
    <p class="m-only description">{{ 'ENCODING.CARD.TITLE' | translate }}</p>
  </div>
</div>
<div class="actions-history" id="action-history">
  <app-point-history-data-wrapper></app-point-history-data-wrapper>
</div>
