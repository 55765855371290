<div class="backToCartBanner" id="top">
  <img *ngIf="screen.isDesktop" src="/assets/images/logo.svg" />
  <img *ngIf="screen.isMobile" src="/assets/images/m-logo.svg" />
  <svg viewbox="0 0 32 32" (click)="goToCart()">
    <use xlink:href="#icon-close"></use>
  </svg>
</div>

<div class="order-container" *ngIf="resourcesStore.isLoaded()">
  <app-page-title [mainTitle]="resourcesStore.i18n().resources.checkout.mainTitle" [subTitle]="resourcesStore.i18n().resources.checkout.shortDescription"> </app-page-title>
  <app-form-stepper
    *ngIf="!userStore.isAuthenticated() || (checkoutStore.isLoaded() && checkoutStore.cart()?.id && checkoutStore.checkoutType() == CheckoutType.ESHOP)"
    (requestNavigateToStep)="changeStep($event)"
    [currentStep]="checkoutStore.userNavigationStep()"
    [steps]="[
      { icon: null, step: null, title: resourcesStore.i18n().resources.checkout.mainSteps[0].label, canNavigate: false },
      {
        icon: null,
        step: CheckoutOrValidationProgressionStep.CONTACT,
        title: resourcesStore.i18n().resources.checkoutConsultant.secondTitle,
        canNavigate: userStore.isAuthenticated() && !checkoutStore.isStepContact()
      },
      {
        icon: null,
        step: CheckoutOrValidationProgressionStep.DELIVERY,
        title: resourcesStore.i18n().resources.checkout.mainSteps[1].label,
        canNavigate: checkoutStore.isStepPayment()
      },
      { icon: null, step: CheckoutOrValidationProgressionStep.PAYMENT, title: resourcesStore.i18n().resources.checkout.mainSteps[2].label, canNavigate: false }
    ]">
  </app-form-stepper>

  <div *ngIf="!userStore.isAuthenticated()" class="fidelity-frame frame-container flex j-center">
    <app-login [isComponent]="true" [redirectUri]="'/' + contextStore.locale() + '/public/checkout'"></app-login>
  </div>

  <ng-container *ngIf="checkoutStore.isLoaded() && userStore.isAuthenticated() && checkoutStore.cart()?.id">
    <app-form-stepper
      *ngIf="checkoutStore.checkoutType() == CheckoutType.DEMO"
      (requestNavigateToStep)="changeStep($event)"
      [currentStep]="checkoutStore.userNavigationStep()"
      [isDemo]="true"
      [steps]="[
        { step: CheckoutOrValidationProgressionStep.ADVANTAGES, icon: 'reduction', title: 'DEMO.TUNNEL.REDUCTIONS' | translate, canNavigate: !checkoutStore.isStepAdvantage() },
        { step: CheckoutOrValidationProgressionStep.DELIVERY, icon: 'truck', title: 'DEMO.TUNNEL.DELIVERY' | translate, canNavigate: checkoutStore.isStepPayment() },
        { step: CheckoutOrValidationProgressionStep.PAYMENT, icon: 'bank-billet', title: 'DEMO.TUNNEL.PAYMENT' | translate, canNavigate: false }
      ]">
    </app-form-stepper>
    <!--    TODO Handling Errors -->
    <!--    <div class="error-box" *ngIf="display.orderHasErrors()">-->
    <!--      <div class="flex row ai-center">-->
    <!--        <svg viewBox="0 0 28 28" class="icon-warning">-->
    <!--          <use xlink:href="#icon-warning"></use>-->
    <!--        </svg>-->
    <!--        <div>-->
    <!--          <span>{{ (orderService.panierOrder$ | async).errorMessage }}</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div *ngIf="checkoutStore.checkoutType() == CheckoutType.DEMO">
      <!--      TODO: Pertinence??-->
      <!--      <app-order-payment-to-delegate-info-box *ngIf="checkoutState().isStepPayment && checkoutState().cart.paymentType == PaymentTypeObject.HandledByDelegate"></app-order-payment-to-delegate-info-box>-->
      <div class="frame-container flex j-center ai-start" [ngClass]="checkoutStore.isStepAdvantage() ? 'flex-step-1' : checkoutStore.isStepDelivery() ? 'flex-step-2' : 'flex-step-3'">
        <div class="order-content flex-2 fullwidth-only">
          <app-order-advantages
            *ngIf="checkoutStore.isStepAdvantage()"
            [cart]="checkoutStore.cart()"
            [walletAvailableAmount]="checkoutStore.advantages()?.wallet.amount"
            [vouchers]="checkoutStore.advantages()?.vouchers"
            [vouchersInCart]="checkoutStore.cart()?.cartVouchers"
            [voucherEncodingStatus]="checkoutStore.voucherEncoding()"
            [voucherSelectionStatus]="checkoutStore.voucherSelection()"
            [walletConsumptionStatus]="checkoutStore.walletEncoding()"
            (requestValidateEncodedVoucherCode)="validateVoucherCode($event)"
            (requestUseVoucher)="validateVoucherCode($event, true)"
            (requestUpdateWalletAmount)="updateWalletAmount($event)"
            (requestResetStatuses)="resetAdvantageStatuses()"></app-order-advantages>
          <app-order-delivery
            *ngIf="checkoutStore.isStepDelivery()"
            [cart]="checkoutStore.cart()"
            [internalDeliveryData]="checkoutStore.internalDeliveryData()"
            [delegate]="checkoutStore.delegate()"
            [client]="userStore.profile()"
            [deliveryOptions]="checkoutStore.deliveryOptions()"
            (requestSaveDelivery)="persistDelivery()"
            (requestSaveInternalDeliveryInfo)="saveDeliveryInfoInternally($event)"
            (requestSaveInternalDeliveryAddress)="saveDeliveryAddressInternally($event)"></app-order-delivery>
          <app-order-demo-payment
            *ngIf="checkoutStore.isStepPayment()"
            [cart]="checkoutStore.cart()"
            [internalPaymentType]="checkoutStore.internalPaymentType()"
            [paymentOptions]="checkoutStore.paymentOptions()?.paymentOptions"
            [delegate]="checkoutStore.delegate()"
            (requestSignDip)="signDIP()"
            (requestSaveInternalPaymentType)="savePaymentTypeInternally($event)"></app-order-demo-payment>
        </div>

        <div class="order-summary-content flex-1 fullwidth-only">
          <app-order-summary
            [cart]="checkoutStore.cart()"
            [internalDeliveryData]="checkoutStore.internalDeliveryData()"
            [step]="checkoutStore.userNavigationStep()"
            [checkoutType]="checkoutStore.checkoutType()"
            [disableCTA]="checkoutStore.disableCTA()"
            [showCTA]="checkoutStore.showCTA()"
            [hideProductList]="checkoutStore.hideProductList()"
            (requestValidateCurrentStep)="validateCurrentStep()"
            (requestOpenDeliveryInfoModal)="openDeliveryInfoModal()"
            (requestDeleteWallet)="updateWalletAmount($event)"
            (requestDeleteVoucher)="deleteVoucher($event)"
            (requestGoToTheTop)="goToTheTop()"></app-order-summary>
        </div>
      </div>
    </div>

    <div *ngIf="checkoutStore.checkoutType() == CheckoutType.ESHOP">
      <div class="frame-container flex j-center ai-start" [ngClass]="checkoutStore.isStepContact() ? 'flex-step-1' : checkoutStore.isStepDelivery() ? 'flex-step-2' : 'flex-step-3'">
        <div class="order-content flex-2 fullwidth-only">
          <app-order-contact *ngIf="checkoutStore.isStepContact()"></app-order-contact>
          <app-order-delivery
            *ngIf="checkoutStore.isStepDelivery()"
            [cart]="checkoutStore.cart()"
            [internalDeliveryData]="checkoutStore.internalDeliveryData()"
            [delegate]="checkoutStore.delegate()"
            [client]="userStore.profile()"
            [deliveryOptions]="checkoutStore.deliveryOptions()"
            (requestSaveDelivery)="persistDelivery()"
            (requestSaveInternalDeliveryInfo)="saveDeliveryInfoInternally($event)"
            (requestSaveInternalDeliveryAddress)="saveDeliveryAddressInternally($event)"></app-order-delivery>
          <app-order-payment
            *ngIf="checkoutStore.isStepPayment()"
            [cart]="checkoutStore.cart()"
            [internalPaymentType]="checkoutStore.internalPaymentType()"
            [paymentOptions]="checkoutStore.paymentOptions()?.paymentOptions"
            [walletAvailableAmount]="checkoutStore.advantages().wallet.amount"
            [vouchers]="checkoutStore.advantages().vouchers"
            [vouchersInCart]="checkoutStore.cart().cartVouchers"
            [voucherEncodingStatus]="checkoutStore.voucherEncoding()"
            [voucherSelectionStatus]="checkoutStore.voucherSelection()"
            [walletConsumptionStatus]="checkoutStore.walletEncoding()"
            (requestSignDip)="signDIP()"
            (requestValidateEncodedVoucherCode)="validateVoucherCode($event)"
            (requestUseVoucher)="validateVoucherCode($event, true)"
            (requestUpdateWalletAmount)="updateWalletAmount($event)"
            (requestResetStatuses)="resetAdvantageStatuses()"
            (requestSaveInternalPaymentType)="savePaymentTypeInternally($event)">
          </app-order-payment>
        </div>

        <div class="order-summary-content flex-1 fullwidth-only">
          <app-order-summary
            [cart]="checkoutStore.cart()"
            [internalDeliveryData]="checkoutStore.internalDeliveryData()"
            [step]="checkoutStore.userNavigationStep()"
            [checkoutType]="checkoutStore.checkoutType()"
            [disableCTA]="checkoutStore.disableCTA()"
            [showCTA]="checkoutStore.showCTA()"
            [hideProductList]="checkoutStore.hideProductList()"
            (requestValidateCurrentStep)="validateCurrentStep()"
            (requestOpenDeliveryInfoModal)="openDeliveryInfoModal()"
            (requestDeleteWallet)="updateWalletAmount($event)"
            (requestDeleteVoucher)="deleteVoucher($event)"
            (requestGoToTheTop)="goToTheTop()"></app-order-summary>
        </div>
      </div>
    </div>

    <app-order-demo-delegate *ngIf="checkoutStore.isStepPayment() && screen.isMobile && !(screen.isTablet && screen.isLandscape)" [delegate]="checkoutStore.delegate()"></app-order-demo-delegate>
    <app-order-demo-actions
      *ngIf="checkoutStore.isStepPayment() && screen.isMobile && !(screen.isTablet && screen.isLandscape)"
      [cart]="checkoutStore.cart()"
      [internalPaymentType]="checkoutStore.internalPaymentType()"
      [disableCTA]="checkoutStore.disableCTA()"
      (requestGoToTheTop)="goToTheTop()"
      (requestValidateCurrentStep)="validateCurrentStep()"></app-order-demo-actions>
    <!--    <app-modal-become-hostess-delegate *ngIf="display.hasOrder()" [currentClient]="currentClient" [wishlistMode]="popupWishlistMode"></app-modal-become-hostess-delegate>-->
    <app-shipping-info-modal [isOpened]="checkoutStore.deliveryInfoModal().isOpened" (requestCloseModal)="closeDeliveryInfoModal()"></app-shipping-info-modal>
    <!--  <app-modal-delivery-payment-confirmation *ngIf="order" (confirm)="doConfirm($event)"></app-modal-delivery-payment-confirmation>-->
  </ng-container>
</div>
