export const environment = {
  production: false,
  staging: true,
  agora: true,
  dev: false,
  isSandbox: true,
  CDNBaseUrl: 'https://d2dp7dpkuijlis.cloudfront.net',
  api_url: 'https://v2-staging.agora-victoria.com',
  identity_url: 'https://connect.victoria-company.com/',
  sockets_url: 'https://v2-staging.agora-victoria.com',
  base_url_benelux: 'https://site-sandbox.be.agora-victoria.com',
  base_url_france: 'https://site-sandbox.fr.agora-victoria.com',
  base_url_allemagne: 'https://site-sandbox.de.agora-victoria.com',
  tenantId: '19c0be80-7703-1102-ea56-b1ddd0bce9e0',

  cookie_domain: 'localhost', //
  cookie_domain_benelux: '.be.agora-victoria.com',
  cookie_domain_france: '.fr.agora-victoria.com',
  cookie_domain_allemagne: '.de.agora-victoria.com',

  base_url: 'http://localhost:4200',
  eshop_url_benelux: 'https://eshop-sandbox.be.agora-victoria.com',
  eshop_url_france: 'https://eshop-sandbox.fr.agora-victoria.com',
  eshop_url_allemagne: 'https://eshop-sandbox.de.agora-victoria.com',

  datadog_client_token: 'pub9e29e1f9b447d1737c8129b9b9bb348b',
  datadog_env: 'sandbox',
  datadog_active: true,

  version: '2024.1.0',
  showVersion: false,

  allowedLocales: { fr: ['fr-fr'], be: ['nl-be', 'fr-be'], de: ['de-de'] },
  supportedLocales: { fr: ['fr-fr'], be: ['nl-be', 'fr-be'], de: ['de-de'] },
  instrumentationKey: 'fe3e7735-fef5-47fe-aff7-e4ca5712ad45',
  intranetUrlByContext: { 1: 'https://{env}.victoria-france.fr', 2: 'https://{env}.victoria-benelux.be', 3: 'https://{env}.victoria-deutschland.de' },
};
