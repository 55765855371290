import { SearchDelegateForm } from '../core/types/size-dropdown.type';
import { AddAdvantageStatus } from '../state/models/addAvantageStatus';
import { CheckoutOrValidationProgressionStep } from '../core/enums/checkoutOrValidationProgressionStep.enum';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { CheckoutService } from '../core/services/V2/checkout.service';
import { CommonService } from '../core/services/V2/common.sevice';
import { ActivatedRoute, Router } from '@angular/router';
import { CartStore } from './cart.store';
import {
  CartResponse,
  DelegateResponse,
  DeliveryAddress,
  DeliveryType,
  DeliveryTypeObject,
  DemoStatusObject,
  GetDemoResponse,
  GetUserAdvantagesResponse,
  PaymentType,
  PaymentTypeObject,
} from '@victoria-company/agora-client';
import { CheckoutType } from '../core/enums/checkout-type.enum';
import { UserStore } from './user.store';
import { ContextStore } from './context.store';
import { CartResponse_DeliveryResponse } from '@victoria-company/agora-client/dist/models';
import { StorageService } from '../core/services/storage.service';
import { DemoService } from '../core/services/V2/demo.service';

export type InternalDeliveryData = { delivery: CartResponse_DeliveryResponse; deliveryFee: number; totalToPay: number };

export interface CheckoutState {
  isLoaded: boolean;
  requireLoadDelegate: boolean;
  advantages: GetUserAdvantagesResponse;
  delegate: DelegateResponse;
  voucherEncoding: AddAdvantageStatus;
  voucherSelection: AddAdvantageStatus;
  walletEncoding: AddAdvantageStatus;
  dip: ArrayBuffer;
  userNavigationStep: CheckoutOrValidationProgressionStep;
  internalDeliveryData: InternalDeliveryData;
  internalPaymentType: PaymentType;
  enterDemoCodeModal: {
    isOpened: boolean;
    demoCode: string;
    error: string;
  };
  deliveryInfoModal: {
    isOpened: boolean;
  };
  searchDelegateModal: {
    isOpened: boolean;
    data: SearchDelegateForm;
  };
  foundDelegatesModal: {
    isOpened: boolean;
    delegates: DelegateResponse[];
  };
}
export const initialState: CheckoutState = {
  isLoaded: false,
  delegate: null,
  advantages: null,
  requireLoadDelegate: true,
  dip: null,
  userNavigationStep: null,
  internalPaymentType: PaymentTypeObject.Digital,
  internalDeliveryData: null,
  deliveryInfoModal: { isOpened: false },
  enterDemoCodeModal: { isOpened: false, error: null, demoCode: null },
  searchDelegateModal: { isOpened: false, data: null },
  foundDelegatesModal: { isOpened: false, delegates: [] },
  voucherEncoding: {
    isBusy: false,
    error: null,
    added: false,
  },
  voucherSelection: {
    isBusy: false,
    error: null,
    added: false,
  },
  walletEncoding: {
    isBusy: false,
    error: null,
    added: false,
  },
};

export const CheckoutStore = signalStore(
  { providedIn: 'root' },
  withDevtools('checkout'),
  withState(initialState),
  withComputed((store, cartStore = inject(CartStore)) => ({
    cart: computed(() => cartStore.cart()),
    demo: computed(() => cartStore.demo()),
    deliveryOptions: computed(() => cartStore.deliveryOptions()),
    paymentOptions: computed(() => {
      const options = cartStore.paymentsOptions();
      if (cartStore.cart()?.isHostessCart) {
        options.paymentOptions = options.paymentOptions.filter(p => p.paymentType == 'HandledByDelegate');
      }
      return options;
    }),
    checkoutType: computed(() => cartStore.checkoutType()),
  })),
  withComputed(store => ({
    isStepAdvantage: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.ADVANTAGES),
    isStepContact: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.CONTACT),
    isStepDelivery: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.DELIVERY),
    isStepDIP: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.DIP),
    isStepPayment: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.PAYMENT),
    isStepPaid: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.PAID),
    hideProductList: computed(() => getShouldHideProductsList(store.userNavigationStep())),
    showCTA: computed(() => store.cart()?.delegateId != null && store.delegate()?.id==store.cart().delegateId),
    disableCTA: computed(() => getShouldDisableCTA(store.internalPaymentType(), store.userNavigationStep(), store.cart(), store.demo())),
  })),
  withMethods((store, active = inject(ActivatedRoute), userStore = inject(UserStore), cartStore = inject(CartStore), checkoutService = inject(CheckoutService), storage = inject(StorageService)) => ({
    async initUserNavigationStep(): Promise<void> {
      if (store.cart() && active.snapshot.queryParams?.p) {
        patchState(store, () => ({
          userNavigationStep: CheckoutOrValidationProgressionStep.PAYMENT,
        }));
      } else if (store.cart() && store.demo()?.demo && store.demo()?.demo.status != 'PreSale' && store.demo()?.demo && store.demo()?.demo.status != 'Closed' && store.demo().roles.length > 0) {
        patchState(store, () => ({
          userNavigationStep: CheckoutOrValidationProgressionStep.ADVANTAGES,
        }));
      } else if (store.cart() && userStore.isAuthenticated) {
        patchState(store, () => ({
          userNavigationStep: CheckoutOrValidationProgressionStep.CONTACT,
        }));
      }
    },
    async setUserNavigationStep(): Promise<void> {
      if (store.cart() && store.demo()?.demo) {
        patchState(store, () => ({
          userNavigationStep: getProgressionStep(store.cart(), store.demo()),
        }));
      } else if (store.cart() && userStore.isAuthenticated) {
        patchState(store, () => ({
          userNavigationStep: getProgressionStep(store.cart(), store.demo()),
        }));
      }
    },
    async setInternalDeliveryDataIfNeeded(): Promise<void> {
      if (store.cart() && store.cart().delivery?.type) {
        patchState(store, () => ({
          internalDeliveryData: { delivery: store.cart().delivery, deliveryFee: store.cart().deliveryFee, totalToPay: store.cart().totalToPay },
        }));
      }
    },
    async addDelegateToCart(delegateId: string): Promise<void> {
      await checkoutService.putDelegateIdToCartPromise(cartStore.cart().id, delegateId);
      storage.delegateId = delegateId;
    },
    async addDemoCodeToCart(code: string): Promise<void> {
      patchState(store, () => ({
        enterDemoCodeModal: {
          isOpened: true,
          error: null,
          demoCode: code,
        },
      }));

      await checkoutService.putDemoCodeToCartPromise(cartStore.cart()?.id, code);
      storage.demoId = code;

      patchState(store, () => ({
        enterDemoCodeModal: {
          isOpened: false,
          error: null,
          demoCode: null,
        },
      }));
    },
    resetAdvantageStatuses(): void {
      patchState(store, () => ({
        voucherEncoding: {
          isBusy: false,
          error: null,
          added: false,
        },
        voucherSelection: {
          isBusy: false,
          error: null,
          added: false,
        },
        walletEncoding: {
          isBusy: false,
          error: null,
          added: false,
        },
      }));
    },
    openAddDemoCodeModal(): void {
      patchState(store, () => ({
        enterDemoCodeModal: {
          isOpened: true,
          error: null,
          demoCode: null,
        },
      }));
    },
    closeAddDemoCodeModal(): void {
      patchState(store, () => ({
        enterDemoCodeModal: {
          isOpened: false,
          error: null,
          demoCode: null,
        },
      }));
    },
    openDeliveryInfoModal(): void {
      patchState(store, () => ({
        deliveryInfoModal: {
          isOpened: true,
        },
      }));
    },
    closeDeliveryInfoModal(): void {
      patchState(store, () => ({
        deliveryInfoModal: {
          isOpened: false,
        },
      }));
    },
    openSearchDelegateModal(): void {
      patchState(store, () => ({
        searchDelegateModal: {
          isOpened: true,
          data: {
            country: null,
            address: null,
            zip: null,
            city: null,
            name: null,
          },
        },
      }));
    },
    closeSearchDelegateModal(): void {
      patchState(store, () => ({
        searchDelegateModal: {
          isOpened: false,
          data: null,
        },
      }));
    },
    openSelectDelegatesModal(delegates: DelegateResponse[]): void {
      patchState(store, () => ({
        foundDelegatesModal: {
          isOpened: true,
          delegates: delegates ?? [],
        },
      }));
    },
    closeSelectDelegatesModal() {
      patchState(store, () => ({
        foundDelegatesModal: {
          isOpened: false,
          delegates: [],
        },
      }));
    },
  })),
  withMethods(
    (
      store,
      contextStore = inject(ContextStore),
      userStore = inject(UserStore),
      cartStore = inject(CartStore),
      demoService = inject(DemoService),
      checkoutService = inject(CheckoutService),
      commonService = inject(CommonService),
      storage = inject(StorageService),
      router = inject(Router)
    ) => ({
      async reset() {
        patchState(store, { ...initialState });
      },
      async loadCheckout(userId: string): Promise<void> {
        let delegate = store.delegate();
        let demo: GetDemoResponse = null;

        const delegateId = storage.delegateId;
        const demoId = storage.demoId;


        console.log("Cookie delegateId : ", delegateId)
        console.log("Cart :", cartStore.cart());

        const loadDelegateFromCookie = (delegateId && cartStore.cart()?.delegateId && delegateId!= cartStore.cart()?.delegateId) || (delegateId && !cartStore.cart()?.delegateId);
        const loadDemoFromCookie = (demoId && cartStore.demo()?.demo?.code && demoId!=cartStore.demo()?.demo?.code && cartStore.demo()?.demo?.status!='Opened') || (demoId && !cartStore.demo())

        const advantages = await checkoutService.getUserAdvantages(userId);

        if(loadDemoFromCookie){
          demo = await demoService.getDemoByCodeOrId(demoId)
          if(demo && demo.demo?.status!="Closed"){
            await store.addDemoCodeToCart(demo.demo?.code);
          } else storage.removeDemoId()
        }

        if(loadDelegateFromCookie){
          delegate = await commonService.getDelegateProfileAsPromise(delegateId);
          if(delegate){
            storage.delegateId = delegate.id;
          }
        } else if (store.requireLoadDelegate() && cartStore.cart()?.delegateId) delegate = await commonService.getDelegateProfileAsPromise(cartStore.cart()?.delegateId);
        else if (store.requireLoadDelegate() && userStore.profile()?.assignedDelegateId) delegate = await commonService.getDelegateProfileAsPromise(userStore.profile()?.assignedDelegateId);
        else if (store.requireLoadDelegate() && userStore.profile()) {
          const lookupResponses = await checkoutService.getDelegateFromProfileInfoPromise(contextStore.locale(), contextStore.contextId(), userStore.profile());
          const mappedProfile: DelegateResponse[] = lookupResponses?.map(response => ({
            id: response.delegateId,
            city: response.city,
            email: response.email,
            affiliateStatus: response.affiliateStatus,
            countryCode: response.countryCode,
            firstname: response.firstName,
            lastname: response.lastName,
            languages: response.languages,
            mobile: response.phone,
            acceptsDeliveries: response.isAcceptingDelivery,
            eShopPitch: response.teaser,
            zipCode: response.zipCode,
            profileImage: response.profileImagePath,
          }));
          delegate = mappedProfile[0] ?? null;
        } else if (store.isLoaded() && !cartStore.cart().id) await router.navigate([`/${contextStore.locale()}/public/cart`]);

        patchState(store, () => ({
          advantages,
          delegate,
          isLoaded: true,
          requireLoadDelegate: false,
          deliveryInfoModal: { isOpened: false },
          enterDemoCodeModal: { isOpened: false, error: null, demoCode: null },
          searchDelegateModal: { isOpened: false, data: null },
          foundDelegatesModal: { isOpened: false, delegates: [] },
          voucherEncoding: {
            isBusy: false,
            error: null,
            added: false,
          },
          voucherSelection: {
            isBusy: false,
            error: null,
            added: false,
          },
          walletEncoding: {
            isBusy: false,
            error: null,
            added: false,
          },
        }));
      },
      async encodeVoucher(cartId: string, voucherCode: string): Promise<void> {
        patchState(store, () => ({
          voucherEncoding: {
            isBusy: true,
            added: false,
            error: null,
          },
        }));

        try {
          await checkoutService.postUserVoucherPromise(cartId, voucherCode);
          patchState(store, () => ({
            voucherEncoding: {
              isBusy: false,
              added: true,
              error: null,
            },
          }));
        } catch (error) {
          patchState(store, () => ({
            voucherEncoding: {
              isBusy: false,
              added: false,
              error: 'An error occurs',
            },
          }));
        }
      },
      async useVoucher(cartId: string, voucherCode: string): Promise<void> {
        patchState(store, () => ({
          voucherSelection: {
            isBusy: true,
            added: false,
            error: null,
          },
        }));

        try {
          await checkoutService.postUserVoucherPromise(cartId, voucherCode);
          patchState(store, () => ({
            voucherSelection: {
              isBusy: false,
              added: true,
              error: null,
            },
          }));
        } catch (error) {
          patchState(store, () => ({
            voucherSelection: {
              isBusy: false,
              added: false,
              error: 'An error occurs',
            },
          }));
        }
      },
      async useWallet(cartId: string, amount: number): Promise<void> {
        patchState(store, () => ({
          walletEncoding: {
            isBusy: true,
            added: false,
            error: null,
          },
        }));
        try {
          await checkoutService.putUseWalletPromise(cartId, amount);
          patchState(store, () => ({
            walletEncoding: {
              isBusy: false,
              added: true,
              error: null,
            },
          }));
        } catch (error) {
          patchState(store, () => ({
            walletEncoding: {
              isBusy: false,
              added: false,
              error: 'An error occurs',
            },
          }));
        }
      },
      async deleteVoucher(cartId: string, voucherCode: string): Promise<void> {
        await checkoutService.deleteUserVoucherPromise(cartId, voucherCode);
      },
      async checkDIPBox(cartId: string): Promise<void> {
        await checkoutService.postSignDipPromise(cartId);
      },
      async searchForDelegates(data: SearchDelegateForm) {
        const lookupResponses = await checkoutService.getSearchForDelegatesPromise(contextStore.locale(), contextStore.contextId(), data);
        const mappedProfile: DelegateResponse[] = lookupResponses?.map(response => ({
          id: response.delegateId,
          city: response.city,
          email: response.email,
          affiliateStatus: response.affiliateStatus,
          countryCode: response.countryCode,
          firstname: response.firstName,
          lastname: response.lastName,
          languages: response.languages,
          mobile: response.phone,
          acceptsDeliveries: response.isAcceptingDelivery,
          eShopPitch: response.teaser,
          zipCode: response.zipCode,
          profileImage: response.profileImagePath,
        }));

        store.openSelectDelegatesModal(mappedProfile ?? []);
      },
      async addDeliveryMethod(cartId: string, deliveryType: DeliveryType, address: DeliveryAddress): Promise<void> {
        await checkoutService.putDeliveryMethodPromise(cartId, deliveryType, address);
      },
      async goToStep(userNavigationStep: CheckoutOrValidationProgressionStep): Promise<void> {
        patchState(store, { userNavigationStep });
      },
      async goToNextStep(currentStep: CheckoutOrValidationProgressionStep): Promise<void> {
        switch (currentStep) {
          case CheckoutOrValidationProgressionStep.ADVANTAGES:
          case CheckoutOrValidationProgressionStep.CONTACT:
            patchState(store, { userNavigationStep: CheckoutOrValidationProgressionStep.DELIVERY });
            break;
          case CheckoutOrValidationProgressionStep.DELIVERY:
            patchState(store, { userNavigationStep: CheckoutOrValidationProgressionStep.PAYMENT });
            break;
        }
      },
      async confirmCart(cartId: string): Promise<void> {
        await checkoutService.postConfirmCartPromise(cartId);
        await router.navigate([`/${contextStore.locale()}/public/checkout/${cartId}/confirmation`]);
      },
      async requestCartPayment(cartId: string, locale: string, paymentType: PaymentType, redirectUrl: string): Promise<void> {
        const response = await commonService.postPaymentPromise(cartId, locale, paymentType, redirectUrl);
        if (response.checkoutUrl != null) window.location.href = response.checkoutUrl;
        else await router.navigateByUrl(redirectUrl);
      },
      saveSelectedDelegateInternally(delegate: DelegateResponse) {
        patchState(store, () => ({
          delegate,
        }));

        store.closeSelectDelegatesModal();
      },
      setInternalDeliveryAddress(address: DeliveryAddress) {
        patchState(store, {
          internalDeliveryData: {
            ...store.internalDeliveryData(),
            delivery: {
              ...store.internalDeliveryData()?.delivery,
              address,
            },
          },
        });
      },
      setInternalDeliveryType(params: { deliveryType: DeliveryType; deliveryFee: number }) {
        patchState(store, () => ({
          internalDeliveryData: {
            delivery: {
              type: params.deliveryType,
              address: params.deliveryType == DeliveryTypeObject.ToDelegate ? null : store.internalDeliveryData()?.delivery?.address ?? null,
            },
            totalToPay: store.cart().total > 0 ? store.cart().total + params.deliveryFee : params.deliveryFee, //TODO calculation
            deliveryFee: params.deliveryFee, //TODO calculation
          },
        }));
      },
      setInternalPaymentType(paymentType: PaymentType) {
        patchState(store, () => ({ internalPaymentType: paymentType }));
      },
    })
  ),
  withMethods((store, checkoutService = inject(CheckoutService)) => ({
    async confirmAdvantageViewed(cartId: string): Promise<void> {
      if (!store.cart().advantageScreenViewed) await checkoutService.postAdvantageViewedPromise(cartId);
      await store.goToNextStep(CheckoutOrValidationProgressionStep.ADVANTAGES);
    },
  }))
);

const getCheckoutType = (cart: CartResponse, demo: GetDemoResponse) => {
  if (cart) {
    if (demo?.demo && demo?.demo?.status != DemoStatusObject.PreSale && demo?.demo?.status != DemoStatusObject.Closed && demo.roles.length > 0) return CheckoutType.DEMO;
    else return CheckoutType.ESHOP;
  } else return null;
};

const getProgressionStep = (cart: CartResponse, demo: GetDemoResponse) => {
  const checkoutType = getCheckoutType(cart, demo);

  if (cart) {
    if (cart.delivery?.type != undefined) return CheckoutOrValidationProgressionStep.PAYMENT;
    else if (checkoutType == CheckoutType.ESHOP && cart.delegateId) return CheckoutOrValidationProgressionStep.DELIVERY;
    else if (checkoutType == CheckoutType.DEMO && cart.advantageScreenViewed) return CheckoutOrValidationProgressionStep.DELIVERY;
    else if (checkoutType == CheckoutType.ESHOP) return CheckoutOrValidationProgressionStep.CONTACT;
    else return CheckoutOrValidationProgressionStep.ADVANTAGES;
  } else return null;
};

const getShouldDisableCTA = (internalPaymentType: PaymentType, userNavigationStep: CheckoutOrValidationProgressionStep, cart: CartResponse, demo: GetDemoResponse) => {
  if (userNavigationStep == CheckoutOrValidationProgressionStep.DELIVERY && !cart.delivery.type) return true;
  else if (
    userNavigationStep == CheckoutOrValidationProgressionStep.PAYMENT &&
    ((cart?.totalToPay > 0 && internalPaymentType == null) || !cart.delivery?.type || (cart.preContractualInformationDocument.required && !cart.preContractualInformationDocument?.signed))
  )
    return true;
  else return false;
};

const getShouldHideProductsList = (userNavigationStep: CheckoutOrValidationProgressionStep) => {
  switch (userNavigationStep) {
    case CheckoutOrValidationProgressionStep.CONTACT:
    case CheckoutOrValidationProgressionStep.ADVANTAGES:
    case CheckoutOrValidationProgressionStep.DELIVERY:
      return true;
    default:
      return false;
  }
};
